import styled from 'styled-components';

import Variables from './Variables';

type Card = {
	padding?: string;
	margin?: string;
}
export const Card = styled.div`
	background: #FFFFFF;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	border-radius: 1rem;
	padding: ${({ padding }: Card) => padding || '1.5rem 1rem'};
	margin: ${({ margin }: Card) => margin || '0'};
	width: 100%;

	@media (min-width: 768px) {
		padding: ${({ padding }: Card) => padding || '2.5rem 1.5rem'};
	}
`;

export const Bold = styled.span`
	font-weight: 600;
`;

export const Bolder = styled.span`
	font-weight: 700;
`;

type TextProps = {
	fontSize?: number;
	margin?: string;
	textAlign?: string;
}
export const Text = styled.p`
	font-size: ${({ fontSize }: TextProps) => `${fontSize || 1}rem`};
	margin: ${({ margin }: TextProps) => margin || 0};
	text-align: ${({ textAlign }: TextProps) => textAlign || 'left'};
`;

type TextLinkProps = {
	disabled?: boolean;
}
export const TextLink = styled.a`
	font-size: 1rem;
	color: ${Variables.colors.main};
	cursor: pointer;
	text-decoration: underline;

	${({ disabled }: TextLinkProps) => disabled && 'pointer-events: none;'}
`;

type ImageProps = {
	height?: number;
	margin?: string;
	width?: number;
}
export const Image = styled.img`
	${({ height }: ImageProps) => height && `height: ${height}px;`}
	margin: ${({ margin }: ImageProps) => margin || 0};
	width: ${({ width }: ImageProps) => width ? `width: ${width}px;` : 'auto'};
`;
