import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import { HomeTypes } from './home/types';
import { SearchTypes } from './search/types';
import { EventTypes } from './event/types';
import { OrganizationTypes } from './organization/types';
import { TicketsTypes } from './tickets/types';

import { getMainEventsSaga, getFeaturedEventsSaga, getUpcomingEventsSaga, getAboutSaga } from './home/sagas';
import { getSearchSaga } from './search/sagas';
import { getEventSaga, countEventViewsSaga } from './event/sagas';
import { getTicketsSaga,downloadAllTicketsSaga, downloadUniqueTicketSaga } from './tickets/sagas';
import { getOrganizationSaga, acceptCollaboratorInviteSaga } from './organization/sagas';
import { getCategoriesSaga } from './categories/sagas';
import { CategoriesTypes } from './categories/types';

export default function* rootSaga() {
	return yield all([
		takeLatest(HomeTypes.GET_MAIN_EVENTS, getMainEventsSaga),
		takeLatest(HomeTypes.GET_FEATURED_EVENTS, getFeaturedEventsSaga),
		takeLatest(HomeTypes.GET_UPCOMING_EVENTS, getUpcomingEventsSaga),
		takeLatest(HomeTypes.GET_ABOUT, getAboutSaga),

		takeLatest(SearchTypes.GET_SEARCH, getSearchSaga),
		takeLatest(SearchTypes.GET_MORE, getSearchSaga),

		takeLatest(EventTypes.GET_EVENT, getEventSaga),
		takeLatest(EventTypes.COUNT_EVENT_VIEWS, countEventViewsSaga),

		takeLatest(OrganizationTypes.GET_ORGANIZATION, getOrganizationSaga),
		takeLatest(OrganizationTypes.ACCEPT_COLLABORATOR_INVITE, acceptCollaboratorInviteSaga),

		takeEvery(TicketsTypes.GET_TICKETS, getTicketsSaga),
		takeEvery(TicketsTypes.DOWNLOAD_ALL_TICKETS, downloadAllTicketsSaga),
		takeEvery(TicketsTypes.DOWNLOAD_UNIQUE_TICKET, downloadUniqueTicketSaga),

		takeEvery(CategoriesTypes.GET_CATEGORIES, getCategoriesSaga),
	]);
}
