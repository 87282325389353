import React from 'react';
import Routes from 'routes/routes';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ToastContainer } from 'react-toastify';
import 'styles/vendor/toastify.css';

import GlobalStyle from 'styles/GlobalStyles';
import { NavBar, Footer, ScrollToTop, Help } from 'components/standalone';
import { PageWrapper } from 'styles';
import store from './store';
import Seo from './components/standalone/Seo/Seo';
import GlobalContext from 'context';

declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace JSX {
		interface IntrinsicElements {
			'io-tickets': any;
			'io-checkout': any;
		}
	}

	interface Window {
		gtag: any;
	}
}

const App = () => (
	<>
		<Provider store={store}>
			<GlobalContext>
				<GlobalStyle />
				<SkeletonTheme color="#d3d3d3" highlightColor="#ccc">
					<Router>
						<PageWrapper>
							<Seo />
							<NavBar />
							<Routes />
							<Footer />
							<ScrollToTop />
							<Help />
						</PageWrapper>
					</Router>
				</SkeletonTheme>
			</GlobalContext>
			<ToastContainer
				position="top-center"
				hideProgressBar={false}
				newestOnTop={false}
				pauseOnFocusLoss
				draggable
				style={{ "top": "80px" }}
			/>
		</Provider>
	</>
);

export default App;
