import { call, put } from 'redux-saga/effects';

import { loadFailure, loadEvent } from './actions';
import { ordersApi } from '../../../services/Api';

export function* getEventSaga(action: any) {
	try {
		const response = yield call(ordersApi.get, `/public/event/url?url=${action.payload.url}&appType=WEB`);
		yield put(loadEvent(response.data));
	} catch (err) {
		yield put(loadFailure());
	}
}

export function* countEventViewsSaga(action: any) {
	try {
		const params = { mid: action.payload.mid };
		yield call(ordersApi.get, `/public/event/${action.payload.eventId}/count-views`, { params });
	} catch {

	}
}
