import UserContext from 'context/user';
import React, { useContext } from 'react';
import AuthService from 'services/AuthService';
import styled from 'styled-components';

import { ButtonOutline } from 'styles/Form';

const SocialButton = styled(ButtonOutline)`
	border-color: ${({ type }: any) => type === 'FACEBOOK' ? '#3B5998' : '#EA4435'};
	color: ${({ type }: any) => type === 'FACEBOOK' ? '#3B5998' : '#EA4435'};
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;

	&:not(:disabled):hover {
		color: ${({ type }: any) => type === 'FACEBOOK' ? '#3B5998' : '#EA4435'};
		background-color: #f3f3f3;
		filter: brightness(1);
	}
`;

const SocialButtonIcon = styled.img`
	height: 1.625rem;
	margin: 0 0.75rem;
	width: 1.625rem;
`;

const SocialAuthButton = ({ type, authSuccessFn }: any) => {
	const { setState } = useContext(UserContext);

	const callAuth = async () => {
		const url = `${process.env.REACT_APP_URL}/auth/api/oauth2/authorization/${type === 'FACEBOOK' ? 'fb' : 'gg'}-${process.env.REACT_APP_TENANT_ID}`
		const win = window.open(url, '_blank', 'popup=yes,width=500,height=600');

		var timer = setInterval(function() {
			if(win?.closed) {
				clearInterval(timer);

				if (AuthService.isAuthenticated()) {
					AuthService.getUserData(AuthService.getAccessToken())
						.then(({ data }: any) => {
							AuthService.setUserData(data);
							setState({
								isLogged: true,
								firstName: data.firstName,
								lastName: data.lastName,
								photoUrl: data.photoUrl,
								username: data.username,
							});
							authSuccessFn(type);
						});
				}
			}
		}, 1000);
	}

	return (
		<SocialButton width="100%" margin="0 0 0.75rem" onClick={callAuth} type={type}>
			<>Acessar com {`${type === 'FACEBOOK' ? 'Facebook' : 'Google'}`}</>

			<SocialButtonIcon src={`${process.env.PUBLIC_URL}/icons/icon-login-${type === 'FACEBOOK' ? 'facebook' : 'google'}.svg`} />
		</SocialButton>
	);
}

export default SocialAuthButton;
