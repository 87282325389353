import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import axios from 'axios';

import { authApi } from './Api';
import { CONSTANTS } from 'config/constants';
import { User } from 'shared/interfaces';

interface Token {
	access_token: string;
	expires_in: number;
	jti: string;
	refresh_token: string;
	scope: string;
	token_type: string;
}

interface AccessTokenDecoded {
	client_id: string;
	exp: number;
	jti: string;
	scope: string[],
	tenant_id: string;
}

const AuthService = (() => {

	function login(username: string, password: string, type = 'DEFAULT', recaptcha = '') {
		const basicAuth = type === 'PHONE' ? process.env.REACT_APP_BASIC_PHONE_AUTH : process.env.REACT_APP_BASIC_AUTH;

		const headers = {
			'Authorization': basicAuth,
			'Recaptcha': recaptcha,
		};

		const params = {
			'grant_type': 'password',
			'username': username,
			'password': password,
			'tenant_id': process.env.REACT_APP_TENANT_ID
		};

		return authApi.post('/oauth/token', null, { params, headers });
	}

	function setToken(token: Token) {
		const cookies = new Cookies();
		const tokenDecoded: AccessTokenDecoded = jwt_decode(token.access_token);

		cookies.set(CONSTANTS.CURRENT_TOKEN, token.access_token, { path: '/', domain: process.env.REACT_APP_DOMAIN_COOKIE });
		cookies.set(CONSTANTS.TOKEN_EXPIRATION_DATE, tokenDecoded.exp, { path: '/', domain: process.env.REACT_APP_DOMAIN_COOKIE });
		cookies.set(CONSTANTS.REFRESH_TOKEN, token.refresh_token, { path: '/', domain: process.env.REACT_APP_DOMAIN_COOKIE });
	}

	function getUserData(token: string) {
		const headers = {
			'Authorization': `Bearer ${token}`,
			'Content-Type': 'application/json'
		};

		return authApi.get('/oauth/user/me', { headers });
	}

	function setUserData(user: User) {
		localStorage.setItem(CONSTANTS.CURRENT_USER, JSON.stringify(user));
	}

	function readUserData() {
		const currentUser = localStorage.getItem(CONSTANTS.CURRENT_USER);
		if (currentUser) {
			return JSON.parse(currentUser);
		}
		return null;
	}

	function clearSession() {
		const cookies = new Cookies();
		localStorage.removeItem(CONSTANTS.CURRENT_USER);
		cookies.remove(CONSTANTS.CURRENT_TOKEN, { path: '/', domain: process.env.REACT_APP_DOMAIN_COOKIE });
		cookies.remove(CONSTANTS.TOKEN_EXPIRATION_DATE, { path: '/', domain: process.env.REACT_APP_DOMAIN_COOKIE });
		cookies.remove(CONSTANTS.REFRESH_TOKEN, { path: '/', domain: process.env.REACT_APP_DOMAIN_COOKIE });
	}

	function clearCurrentUser() {
		localStorage.removeItem(CONSTANTS.CURRENT_USER);
	}

	function isAuthenticated() {
		const currentToken = getAccessToken();
		if (currentToken) {
			const expirationToken = AuthService.getTokenExpirationDate();
			if (Date.now() <= parseInt(expirationToken) * 1000) {
				return true;
			}
		}
		return false;
	}

	function hasLocalUser() {
		const currentUser = localStorage.getItem(CONSTANTS.CURRENT_USER);
		if (currentUser) {
			return true;
		}
		return false;
	}

	function getAccessToken() {
		const cookies = new Cookies();
		const currentToken = cookies.get(CONSTANTS.CURRENT_TOKEN);

		if (currentToken) {
			return currentToken;
		}
		return '';
	}

	function getTokenExpirationDate() {
		const cookies = new Cookies();
		const expirationDate = cookies.get(CONSTANTS.TOKEN_EXPIRATION_DATE);

		if (expirationDate) {
			return expirationDate;
		}
		return '';
	}

	function getRefreshToken() {
		const cookies = new Cookies();
		const refreshToken = cookies.get(CONSTANTS.REFRESH_TOKEN);

		if (refreshToken) {
			return refreshToken;
		}
		return '';
	}
	function sendWhatsappAuthCode(params: any) {
		const headers = { tenant_id: process.env.REACT_APP_TENANT_ID };
		return authApi.post('/v3/public/user/auth/send-code', null, { headers, params });
	}
	function resendWhatsappAuthCode(params: any) {
		const headers = { tenant_id: process.env.REACT_APP_TENANT_ID };
		return authApi.post('/v3/public/user/auth/resend-code', null, { headers, params });
	}

	// ---------- Recovery Phone Access
	function recoveryPhoneValidateEmail(params: any, recaptcha: string) {
		const headers = { recaptcha };
		return authApi.get('/v3/public/user/regain-access/email', { params, headers });
	}

	function recoveryPhoneResendCode(apiKey: string) {
		const headers = { 'api_key': apiKey };
		return authApi.get('/v3/public/user/regain-access/resend-code', { headers });
	}

	function recoveryPhoneValidateCode(params: any, apiKey: string) {
		const headers = { 'api_key': apiKey };
		return authApi.get('/v3/public/user/regain-access/validate-code', { params, headers });
	}

	return {
		login: login,
		setToken: setToken,
		getUserData: getUserData,
		setUserData: setUserData,
		readUserData: readUserData,
		clearSession: clearSession,
		clearCurrentUser: clearCurrentUser,
		isAuthenticated: isAuthenticated,
		getAccessToken: getAccessToken,
		getTokenExpirationDate: getTokenExpirationDate,
		getRefreshToken: getRefreshToken,
		hasLocalUser: hasLocalUser,

		recoveryPhoneValidateEmail,
		recoveryPhoneResendCode,
		recoveryPhoneValidateCode,
		sendWhatsappAuthCode: sendWhatsappAuthCode,
		resendWhatsappAuthCode: resendWhatsappAuthCode
	}
})();

export default AuthService;
