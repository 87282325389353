import { eventApi, ordersApi } from './Api';

const EventService = (() => {
	function getEvent(url: string) {
		const params = { url, appType: 'WEB' };
		return ordersApi.get(`/public/event/url`, { params });
	}

	function checkAffiliate(eventId: string, code: string) {
		return ordersApi.get(`/public/affiliate/code?eventId=${eventId}&code=${code}`);
	}

	function getMarketingData(eventId: string) {
		return eventApi.get(`/public/event/${eventId}/marketing`);
	}

	function getAdditionalMessage(eventId: string) {
		return eventApi.get(`/public/event/${eventId}/cancellation-description`);
	}

	function getEventDescription(eventId: string) {
		return ordersApi.get(`/public/event/${eventId}/description`);
	}

	return {
		getEvent: getEvent,
		checkAffiliate: checkAffiliate,
		getMarketingData: getMarketingData,
		getAdditionalMessage: getAdditionalMessage,
		getEventDescription: getEventDescription,
	}
})();

export default EventService;
