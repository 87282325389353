import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ChevronLeft } from 'react-feather';

import { Button, Input, InputErrorMessage, InputWrapper, Label, MaskInput } from 'styles/Form';
import UserService from 'services/UserService';
import { RenderIf } from 'components/layout';
import * as S from '../styles';
import { isValidCpf } from 'utils/appUtils';
import PhoneAuthResendCode from '../PhoneAuthResendCode/PhoneAuthResendCode';

enum PHONE_REGISTER_VIEW {
	USER_VIEW = 'USER_VIEW',
	PHONE_VIEW = 'PHONE_VIEW',
	CODE_VIEW = 'CODE_VIEW',
	EMAIL_VIEW = 'EMAIL_VIEW',
}

const PhoneRegister = ({ showBackButton = true, backFn, registerSuccessFn }: any) => {
	const [currentView, setCurrentView] = useState(PHONE_REGISTER_VIEW.USER_VIEW);

	const [isLoading, setIsLoading] = useState(false);

	const [cpf, setCpf] = useState({ value: '', hasError: false });
	const [name, setName] = useState({ value: '', hasError: false });
	const [userForm, setUserForm] = useState({ invalid: true });

	const [phoneNumber, setPhoneNumber] = useState({ value: '', hasError: false });
	const [apiKey, setApiKey] = useState('');

	const [code, setCode] = useState({ value: '', hasError: false });

	const [email, setEmail] = useState({ value: '', hasError: false });
	const [emailConfirmation, setEmailConfirmation] = useState({ value: '', hasError: false });
	const [emailForm, setEmailForm] = useState({ invalid: true });

	useEffect(() => {
		const isFormInvalid = (!cpf.value || cpf.hasError || !name.value || name.hasError);
		setUserForm({invalid: isFormInvalid});
	}, [cpf, name]);

	useEffect(() => {
		const isFormInvalid = (!email.value || email.hasError || !emailConfirmation.value || emailConfirmation.hasError);
		setEmailForm({invalid: isFormInvalid});
	}, [email, emailConfirmation]);

	const changeCpf = (ev: any) => {
		ev.persist();

		const cleanValue = ev.target.value.replace(/[^0-9]/g, '').substring(0, 11);
		setCpf(() => ({
			hasError: cleanValue.length > 0 && !isValidCpf(cleanValue),
			value: cleanValue
		}));
	}

	const changeName = (ev: any) => {
		ev.persist();
		const value = ev.target.value;
		const nameArray = value.split(' ').filter((name: string) => name.length > 0);

		setName(() => ({
			hasError: value.length === 0 || nameArray.length < 2,
			value: value
		}));
	}

	const changePhoneNumber = (ev: any) => {
		ev.persist();
		const cleanValue = ev.target.value.replace(/\D/g,'').substring(0, 11);

		setPhoneNumber(() => ({
			hasError: cleanValue.length === 0 || cleanValue.length < 11,
			value: cleanValue
		}));
	}

	const changeCode = (ev: any) => {
		ev.persist();
		setCode(() => ({
			hasError: ev.target.value.length !== 6,
			value: ev.target.value
		}));
	}

	const changeEmail = (ev: any) => {
		ev.persist();
		setEmail(() => {
			setEmailConfirmation((data) => ({
				...data,
				hasError: email.value.length !== 0 && data.value.length === 0 || data.value !==  ev.target.value || !data.value.match(/^[\w\.\-_]{1,}@[\w\.\-]{6,}/),
			}));

			return {
				hasError: ev.target.value.length === 0 || !ev.target.value.match(/^[\w\.\-_]{1,}@[\w\.\-]{6,}/),
				value: ev.target.value
			}
		});
	}

	const changeEmailConfirmation = (ev: any) => {
		ev.persist();
		setEmailConfirmation(() => ({
			hasError: ev.target.value.length === 0 || ev.target.value !== email.value || !ev.target.value.match(/^[\w\.\-_]{1,}@[\w\.\-]{6,}/),
			value: ev.target.value
		}));
	}

	const goToPhoneData = (ev: any) => {
		ev.preventDefault();
		setIsLoading(() => true);

		UserService.checkDocumentIsValid(cpf.value)
			.then(() => setCurrentView(() => PHONE_REGISTER_VIEW.PHONE_VIEW))
			.catch(({ data }) => toast(data.message, { type: toast.TYPE.ERROR }))
			.finally(() => setIsLoading(() => false));
	}

	const goToCodeData = (ev: any) => {
		ev.preventDefault();
		setIsLoading(() => true);
		UserService.checkPhoneAndSendCode(phoneNumber.value)
			.then(({ data }) => {
				setApiKey(data);
				setCurrentView(() => PHONE_REGISTER_VIEW.CODE_VIEW);
			})
			.catch(({ data }) => toast(data.message, { type: toast.TYPE.ERROR }))
			.finally(() => setIsLoading(() => false));
	}

	const resendWhatsapp = () => {
		UserService.resendWhatsappRegisterCode(phoneNumber.value, apiKey)
			.then(({ data }) => {
				setApiKey(data);
				toast('Código de verificação enviado por Whatsapp', { type: toast.TYPE.SUCCESS });
			})
			.catch(({ data }) => toast(data.message, { type: toast.TYPE.ERROR }));
	}

	const resendSms = () => {
		UserService.resendSmsRegisterCode(phoneNumber.value, apiKey)
			.then(({ data }) => toast(data.message, { type: toast.TYPE.SUCCESS }))
			.catch(({ data }) => toast(data.message, { type: toast.TYPE.ERROR }));
	}

	const goToEmailData = (ev: any) => {
		ev.preventDefault();
		setIsLoading(() => true);
		const params = { phone: phoneNumber.value, code: code.value };

		UserService.validatePhoneRegisterCode(params, apiKey)
			.then(() => setCurrentView(() => PHONE_REGISTER_VIEW.EMAIL_VIEW))
			.catch(({ data }) => toast(data.message, { type: toast.TYPE.ERROR }))
			.finally(() => setIsLoading(() => false));
	}

	const concludeRegister = (ev: any) => {
		ev.preventDefault();
		setIsLoading(() => true);

		const payload = {
			phone: phoneNumber.value,
			name: name.value,
			document: cpf.value,
			code: code.value,
			email: email.value,
			confirmEmail: emailConfirmation.value
		}

		UserService.concludePhoneRegister(payload, apiKey)
			.then(({ data }) => {
				toast(data.message, { type: toast.TYPE.SUCCESS });
				registerSuccessFn('PHONE');
			})
			.catch(({ data }) => toast(data.message, { type: toast.TYPE.ERROR }))
			.finally(() => setIsLoading(false))
	}

	const backView = () => {
		if (currentView === PHONE_REGISTER_VIEW.USER_VIEW) {
			backFn();
			return;
		}

		if (currentView === PHONE_REGISTER_VIEW.PHONE_VIEW) {
			setCurrentView(() => PHONE_REGISTER_VIEW.USER_VIEW);
			return;
		}

		if (currentView === PHONE_REGISTER_VIEW.CODE_VIEW) {
			setCurrentView(() => PHONE_REGISTER_VIEW.PHONE_VIEW);
		}

		if (currentView === PHONE_REGISTER_VIEW.EMAIL_VIEW) {
			setCurrentView(() => PHONE_REGISTER_VIEW.CODE_VIEW);
		}
	}

	return (
		<>
			<S.Header>
				<RenderIf condition={showBackButton || currentView !== PHONE_REGISTER_VIEW.USER_VIEW}>
					<S.ReturnButton onClick={backView}>
						<ChevronLeft size="1rem" />
					</S.ReturnButton>
				</RenderIf>
				<S.Title>Crie sua conta</S.Title>
			</S.Header>

			<RenderIf condition={currentView === PHONE_REGISTER_VIEW.USER_VIEW}>
				<form onSubmit={goToPhoneData}>
					<S.SmallText>Informe seus dados para criar sua conta</S.SmallText>

					<InputWrapper hasError={cpf.hasError}>
						<Label>CPF</Label>
						<MaskInput id="cpf" mask="999.999.999-99" value={cpf.value} maskPlaceholder={null} placeholder="000.000.000-00" onChange={changeCpf} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<InputWrapper hasError={name.hasError}>
						<Label data-required>Nome e sobrenome</Label>
						<Input id="name" placeholder="Insira seu nome e sobrenome" value={name.value} onChange={changeName} />
						<InputErrorMessage>Insira seu nome e sobrenome</InputErrorMessage>
					</InputWrapper>

					<Button
						id="validatePhoneRegisterData"
						width="100%"
						margin="2.25rem 0 0"
						loading={isLoading}
						disabled={userForm.invalid}>Continuar</Button>
				</form>
			</RenderIf>

			<RenderIf condition={currentView === PHONE_REGISTER_VIEW.PHONE_VIEW}>
				<S.SmallText>Informe seus dados para criar sua conta</S.SmallText>
				<form onSubmit={goToCodeData}>
					<InputWrapper hasError={phoneNumber.hasError}>
						<Label data-required>Telefone</Label>
						<MaskInput
							id="phoneNumber"
							mask="(99) 99999-9999"
							value={phoneNumber.value}
							maskPlaceholder={null}
							placeholder="(00) 00000-0000"
							onChange={changePhoneNumber} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<Button
						id="validatePhoneRegisterPhone"
						width="100%"
						margin="2.25rem 0 0"
						disabled={phoneNumber.value.length === 0 || phoneNumber.hasError}
						loading={isLoading}>Continuar</Button>
				</form>
			</RenderIf>

			<RenderIf condition={currentView === PHONE_REGISTER_VIEW.CODE_VIEW}>
				<S.SmallText>Digite o código que você recebeu por SMS ou Whatsapp.</S.SmallText>
				<form onSubmit={goToEmailData}>
					<InputWrapper hasError={code.hasError}>
						<Label data-required>Código</Label>
						<MaskInput
							id="code"
							mask="999999"
							value={code.value}
							maskPlaceholder={null}
							placeholder="000000"
							onChange={changeCode} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<Button
						id="validatePhoneRegisterCode"
						width="100%"
						margin="2.25rem 0 0"
						disabled={code.value.length === 0 || code.hasError}
						loading={isLoading}>Verificar código</Button>
				</form>
				<PhoneAuthResendCode hasOptions resendWhatsappFn={resendWhatsapp} resendSmsFn={resendSms} />
			</RenderIf>

			<RenderIf condition={currentView === PHONE_REGISTER_VIEW.EMAIL_VIEW}>
				<S.SmallText>Informe seus dados para criar sua conta.</S.SmallText>
				<form onSubmit={concludeRegister}>
					<InputWrapper hasError={email.hasError}>
						<Label data-required>E-mail</Label>
						<Input id="email" placeholder="seunome@email.com" value={email.value} onChange={changeEmail} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<InputWrapper hasError={emailConfirmation.hasError}>
						<Label data-required>Confirmar e-mail</Label>
						<Input id="emailConfirmation" placeholder="seunome@email.com" value={emailConfirmation.value} onChange={changeEmailConfirmation} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<S.TermsText>Ao me cadastrar, concordo com os <a href={`${process.env.REACT_APP_FAQ_URL}/termos-de-uso`} target="_blank">Termos de Uso</a> da plataforma</S.TermsText>

					<Button
						id="concludePhoneRegister"
						width="100%"
						margin="2.25rem 0 0"
						disabled={emailForm.invalid}
						loading={isLoading}>Criar conta</Button>
				</form>
			</RenderIf>
		</>
	);
}

export default PhoneRegister;
