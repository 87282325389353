import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import SwiperCore, { Autoplay, Pagination } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from 'react-loading-skeleton';

import { Banner, BannerFallback } from 'components/standalone';
import { Event as ioEvent, HomeState } from 'store/ducks/home/types';
import { getMainEvents } from '../../store/ducks/home/actions';
import { SwiperContainer, SwiperEnd  } from 'styles/Swiper';

SwiperCore.use([Autoplay, Pagination]);

const settings = {
	loop: true,
	onInit: () => {
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 1000);
	},
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
	},
	pagination: {
		clickable: true,
	}
};

interface StateProps {
	home: HomeState;
}

const HomeMainEvents = () => {
	const select = (state: StateProps) => state.home;
	const dispatch = useDispatch();
	const { mainEvents, loadingMainEvents } = useSelector(select);

	useEffect(() => {
		dispatch(getMainEvents());
	}, [dispatch]);

	return(
		<>
			{(!loadingMainEvents && mainEvents.length > 0) && (
				<SwiperContainer>
					<Swiper {...settings}>
							{mainEvents && mainEvents.map(({
							name,
							banner,
							thumbnail,
							startDate,
							locationType,
							locationName,
							url,
							featuredBanner,
							seatsMapActived,
							hasFacebookPixel,
							}: ioEvent) => (
								<SwiperSlide>
									<Banner
										key={url}
										name={name}
										banner={banner}
										thumbnail={thumbnail}
										startDate={startDate}
										locationName={locationName}
										locationType={locationType}
										url={url}
										featuredBanner={featuredBanner}
										seatsMapActived={seatsMapActived}
										hasFacebookPixel={hasFacebookPixel}
									/>
								</SwiperSlide>
							)
						)}
						<SwiperEnd slot="container-end" />
					</Swiper>
				</SwiperContainer>
			)}

			{(!loadingMainEvents && mainEvents.length == 0) && (<BannerFallback />)}

			{loadingMainEvents && <Skeleton style={{marginTop: '50px'}} height={isMobile ? 360 : 560} />}
		</>
	);
};

export default HomeMainEvents;
