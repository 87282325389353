export function getQueryParams(search: string, param: string) {
	let query = new URLSearchParams(search);
	return query.get(param) || null;
}

export function downloadFile(data: any, fileName: string) {
	const a: any = document.createElement('a');
	a.style = 'display: none';
	a.href = data;
	a.download = `${fileName}-${new Date().getTime()}`;
	document.body.appendChild(a);
	a.click();
}

export function createFileUrl(file: any) {
	return window.URL.createObjectURL(new Blob([file], { type: file.type }))
}

export function copyToClipboard(text: string) {
	navigator.clipboard.writeText(text);
}

export async function createFile(base64: string) {
	let res = await fetch(base64);
	let blob = await res?.blob();
	return blob;
}

export function isValidCpf(cpf: string) {
	if (typeof cpf !== 'string') return false;

	if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;

	const cpfArray = cpf.split('').map(el => +el);

	const rest = (count: number) => (cpfArray.slice(0, count-12)
		.reduce( (soma: any, el: any, index: number) => (soma + el * (count-index)), 0 )*10) % 11 % 10

	return rest(10) === cpfArray[9] && rest(11) === cpfArray[10];
}

export function scrollToTop() {
	window.scrollTo(0,0);
}

export function setGlobalPhoneMask(phone: string = '') {
	if (phone) {
		const countryCode = phone.slice(0, 2);
		const ddd = phone.slice(2, 4);
		const firstPart = phone.slice(4, 9);
		const lastPart = phone.slice(9);
		return `+${countryCode} (${ddd}) ${firstPart}-${lastPart}`;
	}

	return '';
}
