import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

import { Loading, ModalAuth } from 'components/standalone';
import { CheckoutState } from 'store/ducks/checkout/types';
import { loadSuccess } from 'store/ducks/checkout/actions';
import { hideMobileOptions, showMobileOptions } from 'store/ducks/nav/actions';
import { Seo } from 'components/standalone';
import { loadCheckout } from 'services/ScriptLoader';
import { RenderIf } from 'components/layout';
import PixelManagement from 'components/layout/pixelManagement/PixelManagement';
import PixelContext from 'context/pixel';
import UserContext from 'context/user';

interface StateProps {
	checkout: CheckoutState,
}

const CheckoutPage = () => {
	const { reserve } = useParams();
	const { state }: any = useLocation();
	const { state: pixelState } = useContext(PixelContext);
	const { state: userState } = useContext(UserContext);
	const [ pixelValid, setPixelValid ] = useState(false);
	const [ isModalOpen, setIsModalOpen ] = useState(false);
	const select = (state: StateProps) => state.checkout;
	const { loading } = useSelector(select);
	const history = useHistory();
	const dispatch = useDispatch();
	const hideLoading = () => loadSuccess();
	let component: any;

	const handle = (comp: any) => {
		component = comp;
	}

	const gotoSuccess = (ev: any) => {
		history.push({
			pathname: '/inscricao-sucesso',
			state: { data: ev.detail, hasMarketing: state?.hasMarketing || false },
		})
	}

	useEffect(() => {
		dispatch(hideMobileOptions())
	}, []);

	useEffect(() => {
		if (!userState.isLogged) {
			setIsModalOpen(() => true);
		}
	}, [userState]);

	const returnToEvent = (ev: any) => {
		history.goBack();
	}

	useEffect(() => {
		loadCheckout(() => dispatch(hideLoading()));

		component.addEventListener('redirect', returnToEvent);
		component.addEventListener('success', gotoSuccess);
	}, []);

	useEffect(() => {
		return () => {
			dispatch(showMobileOptions())
		}
	}, []);

	const triggerInitiateCheckoutEvent = (ev: any) => {
		if (pixelState.active && pixelState.eventInitiateCheckout) {
			const data = {
				currency: process.env.REACT_APP_CURRENCY,
				value: ev.detail.value,
				num_items: ev.detail.quantity,
				contents: ev.detail.items,
			};
			ReactPixel.track('InitiateCheckout', data);
		}
	}

	const triggerPurchaseEvent = (ev: any) => {
		if (pixelState.active && pixelState.eventCheckout) {
			const data = {
				currency: process.env.REACT_APP_CURRENCY,
				value: ev.detail.value,
				num_items: ev.detail.quantity,
				contents: ev.detail.items,
			}
			ReactPixel.track('Purchase', data);
		}
	}

	useEffect(() => {
		if (pixelState.active && !pixelValid) {
			setPixelValid(true);
			component.addEventListener('initCheckout', triggerInitiateCheckoutEvent);
			component.addEventListener('concludeCheckout', triggerPurchaseEvent);
		}
	}, [pixelState]);

	return (
		<>
			<Seo title={`${process.env.REACT_APP_NAME} - Finalização de compra`} />
			<RenderIf condition={state?.hasMarketing}>
				<PixelManagement eventId={state?.eventId} />
			</RenderIf>

			<RenderIf condition={loading}>
				<Loading text="Aguarde um momento, estamos gerando o seu pedido." />
			</RenderIf>

			<RenderIf condition={reserve}>
				<io-checkout
					reserve={reserve}
					tenant={process.env.REACT_APP_TENANT_ID}
					user={JSON.stringify(userState)}
					color={process.env.REACT_APP_COLOR_MAIN}
					ref={handle}
				/>
			</RenderIf>

			<ModalAuth
				needAuth={!userState.isLogged}
				isModalOpen={isModalOpen}
				closeModalFn={() => setIsModalOpen(() => false)}
			/>
		</>
	);
}

export default CheckoutPage;
