import React, { useEffect, useContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

import AuthService from 'services/AuthService';
import { Button, Input, InputErrorMessage, InputWrapper, Label } from 'styles/Form';
import { ChevronLeft } from 'react-feather';
import * as S from '../styles';
import { RenderIf } from 'components/layout';
import UserContext from 'context/user';
import { CONSTANTS } from 'config/constants';

const Login = ({ showBackButton = true, loginSuccessFn, recoveryPasswordFn, changeTypeFn, backFn, registerFn }: any) => {
	const { setState } = useContext(UserContext);
	const [isLoading, setIsLoading] = useState(false);
	const [form, setForm] = useState({ invalid: true });
	const [email, setEmail] = useState({ value: '', hasError: false });
	const [password, setPassword] = useState({ value: '', hasError: false });
	const [captchaToken, setCaptchaToken] = useState('');
	const captchaRef = useRef(null);

	useEffect(() => {
		const isFormInvalid = (!email.value || email.hasError || !password.value || password.hasError);
		setForm({invalid: isFormInvalid});
	}, [email, password]);

	const changeEmail = (ev: any) => {
		ev.persist();
		setEmail(() => ({
			hasError: ev.target.value.length === 0 || !ev.target.value.match(CONSTANTS.VALID_EMAIL_REGEX),
			value: ev.target.value.trim()
		}));
	}

	const changePassword = (ev: any) => {
		ev.persist();
		setPassword(() => ({
			hasError: ev.target.value.length < 6,
			value: ev.target.value
		}));
	}

	const login = (ev: any) => {
		ev.preventDefault();
		setIsLoading(() => true);

		if (AuthService.isAuthenticated()) {
			getUserData(AuthService.getAccessToken());
			return;
		}

		AuthService.login(email.value, password.value, 'DEFAULT', captchaToken)
			.then(({ data: token }: any) => setToken(token))
			.catch(({ data }: any) => {
				resetRecaptcha();
				toast(data.detail, { type: toast.TYPE.ERROR });
				setIsLoading(() => false);
			});
	}

	const setToken = async (token: any) => {
		await AuthService.setToken(token);
		getUserData(token.access_token);
	}

	const getUserData = (accessToken: string) => {
		AuthService.getUserData(accessToken)
			.then(({ data }: any) => {
				AuthService.setUserData(data);
				setUserOnContext(data);
				loginSuccessFn('EMAIL');
			})
			.catch((err: any) => console.log(err))
			.finally(() => setIsLoading(() => false));
	}

	const setUserOnContext = ((user: any) => {
		setState({
			isLogged: true,
			firstName: user.firstName,
			lastName: user.lastName,
			photoUrl: user.photoUrl,
			username: user.username,
		});
	})

	const resetRecaptcha = () => {
		setCaptchaToken('');
		//@ts-ignore
		captchaRef.current.reset();
	}

	return (
		<>
			<S.Header>
				<RenderIf condition={showBackButton}>
					<S.ReturnButton onClick={backFn}>
						<ChevronLeft size="1rem" />
					</S.ReturnButton>
				</RenderIf>
				<S.Title>Acesse sua conta</S.Title>
			</S.Header>

			<RenderIf condition={process.env.REACT_APP_AUTH_TYPE === 'PHONE'}>
				<S.LoginAnnouncement>
					<S.LoginAnnouncementTitle>Novidade!</S.LoginAnnouncementTitle>
					<S.LoginAnnouncementText>Atualize seu cadastro e acesse sua conta apenas com o número de telefone.</S.LoginAnnouncementText>
				</S.LoginAnnouncement>
			</RenderIf>

			<form onSubmit={login}>
				<InputWrapper hasError={email.hasError}>
					<Label data-required>E-mail</Label>
					<Input id="email" placeholder="seunome@email.com" value={email.value} onChange={changeEmail} />
					<InputErrorMessage>Campo inválido</InputErrorMessage>
				</InputWrapper>

				<InputWrapper hasError={password.hasError}>
					<Label data-required>Senha</Label>
					<Input id="password" type="password" value={password.value} onChange={changePassword} />
					<InputErrorMessage>Campo inválido</InputErrorMessage>
				</InputWrapper>

				<ReCAPTCHA
					sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}
					ref={captchaRef}
					onChange={($event) => setCaptchaToken($event || '')}
					onExpired={resetRecaptcha}
				/>

				<S.SmallTextLink onClick={recoveryPasswordFn} style={{marginTop: '10px'}}>Esqueci minha senha</S.SmallTextLink>

				<Button
					width="100%"
					margin="2.25rem 0 0"
					loading={isLoading}
					disabled={form.invalid || !captchaToken}>Acessar conta</Button>

				<RenderIf condition={process.env.REACT_APP_AUTH_TYPE === 'PHONE'}>
					<S.SmallTextLinkColored onClick={changeTypeFn}>Entrar com telefone</S.SmallTextLinkColored>
				</RenderIf>

				<S.SmallTextLinkColored onClick={registerFn}>Cadastre-se</S.SmallTextLinkColored>
			</form>
		</>
	);
}

export default Login;
