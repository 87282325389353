import { call, put } from 'redux-saga/effects';

import { getSearchSuccess, getMoreSuccess, getSearchFailure, setFilters } from './actions';
import { ordersApi } from '../../../services/Api';

export function* getSearchSaga(action: any) {
	try {
		const response = yield call(ordersApi.post, '/public/event/filter', action.payload.filters, { params: action.payload.params});
		yield put(setFilters(action.payload.filters));
		yield put(action.payload.isMore ? getMoreSuccess(response.data) : getSearchSuccess(response.data));
	} catch (err) {
		yield put(getSearchFailure());
	}
}
