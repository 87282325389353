import { call, put } from 'redux-saga/effects';

import {
	loadMainEvents,
	failureMainEvents,
	loadFeaturedEvents,
	failureFeaturedEvents,
	loadUpcomingEvents,
	failureUpcomingEvents,
	getAboutSuccess,
	getAboutFailure }
from './actions';
import { ordersApi } from '../../../services/Api';

export function* getMainEventsSaga() {
	try {
		const response = yield call(ordersApi.get, '/public/event/main-events');
		yield put(loadMainEvents(response.data));
	} catch (err) {
		yield put(failureMainEvents());
	}
}

export function* getFeaturedEventsSaga() {
	try {
		const response = yield call(ordersApi.get, '/public/event/featured-events');
		yield put(loadFeaturedEvents(response.data));
	} catch (err) {
		yield put(failureFeaturedEvents());
	}
}

export function* getUpcomingEventsSaga() {
	try {
		const response = yield call(ordersApi.get, '/public/event/upcoming-events');
		yield put(loadUpcomingEvents(response.data));
	} catch (err) {
		yield put(failureUpcomingEvents());
	}
}

export function* getAboutSaga() {
	try {
		const response = yield call(ordersApi.get, '/public/event/home-info');
		yield put(getAboutSuccess(response.data));
	} catch (err) {
		yield put(getAboutFailure());
	}
}
