import React, { useEffect } from 'react';
import { Card } from 'styles/Commons';
import RenderIf from '../renderIf/RenderIf';

import * as S from './styles';

const Modal = ({ isOpen, title, maxWidth, showHeader = true, children, onClose }: any) => {

	useEffect(() => {
		document.body.style.overflow = (isOpen ? 'hidden' : 'initial');
	}, [isOpen]);

	useEffect(() => () => {
		document.body.style.overflow = 'initial';
	}, []);

	return (
		<>
			{isOpen && (
				<S.Overlay>
					<Card style={{ maxWidth: (maxWidth || '600px') }}>
						<RenderIf condition={showHeader}>
							<S.ModalHeader>
								<S.ModalTitle>{title}</S.ModalTitle>
								<S.ModalClose onClick={onClose} />
							</S.ModalHeader>
						</RenderIf>
						{children}
					</Card>
				</S.Overlay>
			)}
		</>
	);
}

export default Modal;
