// ACTION TYPES
export enum EventTypes {
	GET_EVENT = '@event/GET_EVENT',
	LOAD_EVENT = '@event/LOAD_EVENT',
	LOAD_SUCCESS = '@event/LOAD_SUCCESS',
	LOAD_FAILURE = '@event/LOAD_FAILURE',
	CLEAR_EVENT = '@event/CLEAR_EVENT',
	COUNT_EVENT_VIEWS = '@event/COUNT_EVENT_VIEWS',
}

//DATA TYPES
export interface Event {
	id: string;
	name: string;
	url: string;
	featuredBanner: string;
	banner: string;
	thumbnail?: string;
	startDate: string;
	endDate: string;
	description: string;
	fullAddress: string;
	locationName: string;
	locationType: string;
	status: string;
	tags: string;
	limitedByCpf: boolean;
	tickets: any[],
	salesStartDateInSeconds: number | null,
	showCancellationDescription: boolean,
	hasFacebookPixel: boolean,
	hasMarketing: boolean,
	groupBySector: boolean,
	hasDiscount: boolean,
	isAccreditation: boolean,

	seatsMapActived: boolean;
	seatMap: {
		seatsIoMapkey: string;
	}
}



// STATE TYPE
export interface EventState {
	readonly data: Event;
	readonly loading: boolean;
	readonly error: boolean;
}
